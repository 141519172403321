import { template as template_5f50adbee1914106a1209a1f688c5236 } from "@ember/template-compiler";
const WelcomeHeader = template_5f50adbee1914106a1209a1f688c5236(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
