import { template as template_657959948fef4f02a4d74e572d4cfb13 } from "@ember/template-compiler";
const FKControlMenuContainer = template_657959948fef4f02a4d74e572d4cfb13(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
